import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { EMPTY_FOLDER_MESSAGE } from 'src/strings';

function EmptyCell() {
    return <Typography variant="body2" />;
}

export function EmptyFolderCell({ depth }: { depth: number }) {
    return <div style={{ paddingLeft: `${(depth + 1) * 30}px` }}>{EMPTY_FOLDER_MESSAGE}</div>;
}

export function EmptyFolderRow({ depth }: { depth: number }) {
    return (
        <TableRow>
            <TableCell>
                <EmptyFolderCell depth={depth} />
            </TableCell>
            <TableCell>
                <EmptyCell />
            </TableCell>
            <TableCell>
                <EmptyCell />
            </TableCell>
            <TableCell>
                <EmptyCell />
            </TableCell>
            <TableCell>
                <EmptyCell />
            </TableCell>
        </TableRow>
    );
}
