/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { FileStatus } from 'src/types/files';

// Key: workspace ID
type UploadContainerState = Record<string, FileStatus[]>;

const initialState: UploadContainerState = {};

interface SetFileUploadStatusPayload extends FileStatus {
    workspaceId: string;
}

interface ClearUploadStatusPayload {
    workspaceId: string;
}

export const fileUploadStatus = createSlice({
    name: 'uploadStatus',
    initialState,
    reducers: {
        setFileUploadStatus(
            state: UploadContainerState,
            action: PayloadAction<SetFileUploadStatusPayload>,
        ) {
            const { workspaceId, ...status } = action.payload;

            if (state[workspaceId] === undefined) {
                state[workspaceId] = [];
            }

            const index = state[workspaceId].findIndex((file) => file.fileId === status.fileId);
            if (index !== -1) {
                state[workspaceId][index] = status;
            } else {
                state[workspaceId] = [status, ...state[workspaceId]];
            }
        },
        clearUploadStatus(
            state: UploadContainerState,
            action: PayloadAction<ClearUploadStatusPayload>,
        ) {
            const { workspaceId } = action.payload;
            state[workspaceId] = [];
        },
    },
});
