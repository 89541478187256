import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import { Schema } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { Grid, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';

import { Categories, UserAction } from 'src/metrics.types';
import { VIEW } from 'src/strings';

interface DropDownProps {
    schemas: Schema[];
    selected: string;
    setSelectedSchema: (key: string) => void;
    isLoading: boolean;
}

// TODO: Fix styling
export const DropDown = ({ schemas, selected, setSelectedSchema, isLoading }: DropDownProps) => {
    const { setSidebarContent } = useSidebarContext();
    const handleChange = (event: SelectChangeEvent) => {
        setSidebarContent(undefined);
        setSelectedSchema(event.target.value as string);
        trackUserAction(UserAction.USER_TOGGLED_AFFINITIES_VIEW, {
            category: Categories.AFFINITY_SCHEMA,
        });
    };

    if (isLoading) {
        return <Skeleton animation="wave" variant="rectangular" width={210} height={60} />;
    }
    return (
        <Grid
            container
            gap={2}
            alignItems="center"
            sx={{ minWidth: 120 }}
            direction="row"
            flexWrap="nowrap"
        >
            <Typography id="view-by-select-label">{VIEW}</Typography>
            <Select
                automation-id="select-menu-item-label"
                variant="outlined"
                labelId="view-by-select-label"
                onChange={handleChange}
                value={selected}
                sx={{ width: 300 }}
            >
                {schemas.map((schema) => (
                    <MenuItem
                        key={schema.name}
                        value={schema.name}
                        automation-id={`select-menu-menu-item-${schema.name}`}
                    >
                        {schema.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};
