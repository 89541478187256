import { CSSProperties } from 'react';

export enum Order {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export interface DefaultSortSettings {
    order?: Order;
}

/**
 * FieldDefinition defines the info needed by each column of a table
 * @param sortFunction should sort in ascending order
 */
export interface FieldDefinition<T = any> {
    key: string;
    label?: string | any;
    component?: any;
    styles?: CSSProperties;
    sortFunction?: (a: T, b: T) => number;
    apiSortFunction?: () => void;
    columnWidth?: string | 0;
    type?: object;
    customCellComponent?: boolean;
    tableCellClassName?: string;
    tableHeaderClassName?: string;
    defaultSortSettings?: DefaultSortSettings;
}
