import { common } from '@local/web-design-system/dist/styles/common';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { highlight } from '@local/web-design-system/dist/styles/highlight';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableHeader: {
        ...fonts.h2,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
    },
    uploadingHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: '17px',
        lineHeight: '20px',
        height: '56px',
        minWidth: '250px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    tableSubheader: {
        padding: `${theme.spacing()} 0`,
        height: '60px',
    },
    tableSubheaderRight: {
        display: 'flex',
        alignItems: 'center',
    },
    tableContainer: {
        display: 'flex',
        flexGrow: 1,
    },
    table: {
        ...common(theme).thinScrollBar,
        display: 'flex',
        height: '71vh',
        flexGrow: 1,
    },
    page: {
        flexGrow: 1,
    },
    pageContainer: {
        width: '100%',
    },
    listItemOverrides: {
        height: theme.spacing(7),
    },
    noSearchResults: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(14),
        width: '50%',
        margin: 'auto',
    },
    highlight: {
        ...highlight(theme).highlight,
    },
    container: {},
    pagination: {
        padding: 0,
    },
}));
