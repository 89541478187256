import { ListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics';
import { toSuffixUid } from '@local/webviz/dist/xyz';
import { Dispatch } from '@reduxjs/toolkit';
import capitalize from 'lodash-es/capitalize';
import { useEffect } from 'react';

import { fileNameExtensionRemover } from 'src/pages/workspacePage/workspaceContent/utils';
import { getObjectBySchema } from 'src/store/goose/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    addTreeItem,
    addTreeItemChildren,
    updateTree,
} from 'src/store/visualization/visualizationSlice';
import { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { ROOT_TREE_ID } from 'src/strings';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';
import { useQueue } from 'src/visualization/context/hooks/useQueue';
import type {
    DownholeCollectionType,
    GeologicalModelMeshesType,
    Item,
} from 'src/visualization/types';

import {
    CONCURRENCE_VALUE,
    INTERVAL,
    POINTSET,
    TREE_BATCH_SIZE,
} from '../../ObjectsPanel/ProjectTree/BuildObjectTree.constants';

export function useBuildObjectTree(objects: ListedObject[]) {
    let treeStateUpdate: { [key: string]: TreeStructure } = {};

    const dispatch = useAppDispatch();
    const { enqueue } = useQueue(CONCURRENCE_VALUE);

    function flushDictionary() {
        dispatch(
            updateTree({
                treeDictionary: { ...treeStateUpdate },
            }),
        );
        clearDictionary();
    }

    function addDictionaryItem(treeItem: TreeStructure) {
        if (Object.keys(treeStateUpdate).length > TREE_BATCH_SIZE) {
            flushDictionary();
        }
        const { treeId } = treeItem;
        treeStateUpdate[treeId] = treeItem;
    }

    function clearDictionary() {
        treeStateUpdate = {};
    }

    useEffect(() => {
        if (!objects.length) {
            return;
        }
        clearDictionary();
        const allObjectIds = objects.map((object) => object.object_id);
        addTopLevelFolders(allObjectIds);
        addChildrenForTopLevelFolders(objects);
        flushDictionary();
    }, [objects]);

    const downholeCollections = useAppSelector(
        getObjectBySchema<DownholeCollectionType>(Schemas.DownholeCollectionSchema),
    );

    useEffect(() => {
        if (!downholeCollections.length) {
            return;
        }
        addCollectionsForProjectTree(downholeCollections, addDictionaryItem, dispatch);
        flushDictionary();
    }, [downholeCollections]);

    const geologicalModelMeshes = useAppSelector(
        getObjectBySchema<GeologicalModelMeshesType>(Schemas.GeologicalModelMeshesSchema),
    );

    useEffect(() => {
        addGeologicalModelMeshesToProjectTree(geologicalModelMeshes, addDictionaryItem, dispatch);
        flushDictionary();
    }, [geologicalModelMeshes]);

    function addChildrenForTopLevelFolders(objectList: ListedObject[]) {
        objectList.forEach(({ object_id, name, schema: fullSchema }) => {
            const schema = extractSchema(fullSchema);
            switch (schema) {
                case Schemas.GeologicalModelMeshesSchema:
                case Schemas.DownholeCollectionSchema:
                    addDictionaryItem({
                        treeId: object_id,
                        name: fileNameExtensionRemover(name),
                        parentId: ROOT_TREE_ID,
                        schema,
                        children: [],
                    });
                    enqueue(object_id);
                    break;
                default:
                    addDictionaryItem({
                        treeId: object_id,
                        name: fileNameExtensionRemover(name),
                        parentId: ROOT_TREE_ID,
                        schema,
                    });
            }
        });
    }

    function addTopLevelFolders(rootLevelChildren: string[]) {
        addDictionaryItem({
            treeId: ROOT_TREE_ID,
            name: ROOT_TREE_ID,
            parentId: '',
            schema: '' as Schemas,
            children: rootLevelChildren,
        });
    }
}

function addCollectionsForProjectTree(
    downholeCollections: DownholeCollectionType[],
    addDictionaryItem: (treeItem: TreeStructure) => void,
    dispatch: Dispatch,
) {
    downholeCollections.forEach((downholeCollection) => {
        const folderChildren: string[] = [];
        const { location, name: downholeName } = downholeCollection;
        const treeId = downholeCollection.uuid;
        if (!treeId) {
            trackError('Downhole collection does not have a uuid');
            return;
        }
        if (location) {
            addDictionaryItem({
                treeId: toSuffixUid(treeId, POINTSET),
                name: `${fileNameExtensionRemover(downholeName)} ${capitalize(POINTSET)}`,
                parentId: treeId,
                schema: Schemas.PointsetSchema,
            });
            folderChildren.push(toSuffixUid(treeId, POINTSET));
        }

        downholeCollection.collections.forEach(({ name, collection_type }) => {
            const isCollectionInterval = collection_type === INTERVAL;
            if (isCollectionInterval) {
                addDictionaryItem({
                    treeId: toSuffixUid(treeId, name),
                    name,
                    parentId: treeId,
                    schema: Schemas.DownholeIntervalsSchema,
                });
            } else {
                // eslint-disable-next-line no-console
                console.warn(`Unsupported collection type: ${collection_type}`);
                return;
            }
            folderChildren.push(toSuffixUid(treeId, name));
        });
        // update children of the collection
        dispatch(addTreeItemChildren({ treeId, children: folderChildren }));
    });
}

function addGeologicalModelMeshesToProjectTree(
    geologicalModelMeshes: GeologicalModelMeshesType[],
    addDictionaryItem: (treeItem: TreeStructure) => void,
    dispatch: Dispatch,
) {
    geologicalModelMeshes.forEach((geologicalModelMesh) => {
        const { uuid: treeId } = geologicalModelMesh;
        if (!treeId) {
            trackError('Geological Model Mesh does not have a uuid');
            return;
        }

        processFolders(geologicalModelMesh.folders, treeId);

        function processFolders(parentItems: Item[], parentId: string) {
            parentItems.forEach((parentItem) => {
                const { items, name } = parentItem;
                const itemTreeId = toSuffixUid(parentId, name);

                if (items?.length) {
                    const children = extractFolderChildren(items, itemTreeId);
                    const folderItem = {
                        treeId: itemTreeId,
                        name,
                        parentId,
                        schema: '' as Schemas,
                        children,
                    };
                    dispatch(addTreeItem(folderItem));
                    dispatch(addTreeItemChildren({ treeId: parentId, children: [itemTreeId] }));
                    processFolders(items, itemTreeId);
                } else {
                    addDictionaryItem({
                        treeId: itemTreeId,
                        name,
                        parentId,
                        schema: Schemas.GeologicalModelMeshesSchema,
                    });
                }
            });
        }
    });

    function extractFolderChildren(items: Item[], treeId: string) {
        const children: string[] = [];
        items.forEach((item) => {
            const isFolder = item.items;
            if (!isFolder || isFolder.length) {
                children.push(toSuffixUid(treeId, item.name));
            }
        });

        return children;
    }
}
