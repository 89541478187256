import { GetObjectByIdApiArg } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { trackError } from '@local/metrics';

import { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { Tileset, TriangleMeshType } from 'src/visualization/types';

export function extractTilesetUri(tileset: Tileset, treeItem: TreeStructure): string | undefined {
    const {
        schema,
        groups,
        root: { children },
    } = tileset;
    const classes = schema?.classes;

    if (!classes || !groups) {
        trackError('3D tile schema validation failed');
        return undefined;
    }

    const object = Object.keys(classes).find((mesh) => classes[mesh].name === treeItem.name);

    const objectIndex = groups.findIndex(({ class: className }) => className === object);
    const objectToLoad = children[objectIndex];

    if (!objectToLoad.content) {
        trackError('3D tile content not found');
        return undefined;
    }

    const { uri } = objectToLoad.content;
    return uri;
}

export function getTriangleMeshAttributes(meshObject: TriangleMeshType) {
    const verticesAttributes =
        (meshObject.triangles.vertices.attributes || meshObject.vertex_attributes) ?? [];
    const triangleAttributes =
        (meshObject.triangles.indices.attributes || meshObject.face_attributes) ?? [];
    const attributes = [...verticesAttributes, ...triangleAttributes];
    return attributes;
}

export function generateVisualizationServiceUrl(baseUrl: string, params: GetObjectByIdApiArg) {
    return `${baseUrl}/visualization/orgs/${params.orgId}/workspaces/${params.workspaceId}/geoscience-object/${params.objectId}`;
}

export function createAttributeId(id: string) {
    return `attribute_${id}`;
}
