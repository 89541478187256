/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface DisplaySettingsState {
    workspaceContentMode: 'path' | 'affinity-folders';
    currentView?: string;
}

const initialState: DisplaySettingsState = {
    workspaceContentMode: 'path',
    currentView: undefined,
};

export const displaySettingsSlice = createSlice({
    name: 'displaySettings',
    initialState,
    reducers: {
        setWorkspaceContentMode: (
            state: DisplaySettingsState,
            action: PayloadAction<'path' | 'affinity-folders'>,
        ) => {
            state.workspaceContentMode = action.payload;
        },
        setCurrentView: (state: DisplaySettingsState, action: PayloadAction<string>) => {
            state.currentView = action.payload;
        },
    },
});

const displaySettingsState = (state: RootState) => state.displaySettings;

export const workspaceContentModeSelector: any = createSelector(
    [displaySettingsState],
    (settingsState: DisplaySettingsState) => settingsState.workspaceContentMode,
);

export const currentViewSelector: any = createSelector(
    [displaySettingsState],
    (settingsState: DisplaySettingsState) => settingsState.currentView,
);

export const { setWorkspaceContentMode, setCurrentView } = displaySettingsSlice.actions;
