import { KeyValueInfoTable } from '@local/web-design-system-2/dist/components/KeyValueInfoTable/KeyValueInfoTable';
import File from '@local/web-design-system-2/dist/icons/File';
import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import type { DownloadFileResponse } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { FileActions } from 'src/pages/workspaceFilesPage/fileActions/FileActions';
import {
    TYPE,
    FILE_SIZE,
    FILE_VERSION,
    MODIFIED_BY,
    LAST_MODIFIED,
    FILE_PROPERTIES,
} from 'src/strings';
import { formatBytes, getFileExtension } from 'src/utils/fileUtils';

interface Props {
    file?: DownloadFileResponse;
}

export const Properties = ({ file }: Props) => {
    if (!file) {
        return null;
    }

    const fileSize = formatBytes(file.size);
    return (
        <Stack spacing={2}>
            <Typography variant="h6">{FILE_PROPERTIES}</Typography>
            <Divider />
            <Stack alignItems="center" direction="row" spacing={1}>
                <File color="primary" />
                <Typography automation-id="file-name" title={file.name}>
                    {file.name}
                </Typography>
            </Stack>
            <Stack>
                <KeyValueInfoTable
                    items={[
                        {
                            label: TYPE,
                            safeToLogLabel: true,
                            value: getFileExtension(file.name).toUpperCase(),
                        },
                        {
                            label: FILE_SIZE,
                            safeToLogLabel: true,
                            value: fileSize,
                        },
                        {
                            label: FILE_VERSION,
                            safeToLogLabel: true,
                            value: `V${(file?.versions?.length || 1).toString()}`,
                        },
                        ...(file?.versions && file?.versions?.length > 0
                            ? [
                                  {
                                      label: LAST_MODIFIED,
                                      safeToLogLabel: true,
                                      value: (
                                          <PropertyDate
                                              timestamp={file.versions[0]?.created_at}
                                              automationId="last-modified-value"
                                          />
                                      ),
                                  },
                                  {
                                      label: MODIFIED_BY,
                                      safeToLogLabel: true,
                                      value: file.versions[0]?.created_by?.name,
                                  },
                              ]
                            : []),
                    ]}
                />
            </Stack>
            <FileActions file={file} icons />
        </Stack>
    );
};
