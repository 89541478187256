import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { Upload } from '@local/web-design-system-2';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import { SxProps } from '@mui/material';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/customFileEndpoints';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { ERROR_UPLOADING_FILE, INSUFFICIENT_WORKSPACE_PERMISSION } from 'src/strings';
import { FileStatus, UploadStatus } from 'src/types/files';

import { useStyles } from './UploadFilesButton.styles';

export const UploadFilesButton = ({
    buttonText,
    disabled,
    secondary = false,
    sx = {},
    variant = 'contained',
}: {
    buttonText: string;
    disabled?: boolean;
    secondary?: boolean;
    sx?: SxProps;
    variant?: 'contained' | 'outlined';
}) => {
    const params = useParams();
    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const [updateFile] = useCustomUpsertFileByPathMutation();
    const { upsertFileStatus } = useFileUploadContainer({ workspaceId });
    const uploadFieldRef = useRef<HTMLInputElement>(null);
    const { workspaceUserRole } = useWorkspaceContext();

    const isViewer = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;
    const isDisabled = disabled || isViewer;

    const handleUploadFile = async (file: File, currentFileStatus: FileStatus) => {
        const response = await updateFile({
            workspaceId,
            organisationId,
            filePath: `${file.name}`,
            uploadFile: file,
        });
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: 'error' in response ? UploadStatus.Failed : UploadStatus.Uploaded,
            percentCompleted: 100,
        });
        return response;
    };

    const handleError = async (error: unknown, file: File, currentFileStatus: FileStatus) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Failed,
        });
        addMessage({
            message: ERROR_UPLOADING_FILE,
            severity: 'error',
        });
        trackError(`Error: ${error} uploading file "${file.name}"`);
        return Promise.reject(error);
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentFiles = event.currentTarget.files;
        if (!currentFiles) {
            return;
        }

        const uploadPromises = Array.from(currentFiles).map(async (file) => {
            const fileId = uuid();
            const currentFileStatus: FileStatus = {
                fileName: file.name,
                size: file.size,
                uploadStatus: UploadStatus.Uploading,
                fileId,
                percentCompleted: 0,
            };

            upsertFileStatus(currentFileStatus);

            if (uploadFieldRef.current) {
                uploadFieldRef.current.value = '';
            }

            const response = await handleUploadFile(file, currentFileStatus);
            if ('error' in response) {
                return handleError(response.error, file, currentFileStatus);
            }
            return response;
        });

        await Promise.all(uploadPromises);
    };

    return (
        <Tooltip title={isViewer ? INSUFFICIENT_WORKSPACE_PERMISSION : ''}>
            <Button
                component="label"
                disabled={isDisabled}
                color={secondary ? 'inherit' : 'primary'}
                variant={variant}
                automation-id="upload-files-button"
                fullWidth
                sx={sx}
            >
                <Grid container>
                    <Grid className={classes.buttonText}>
                        <Upload fontSize="small" />
                        {buttonText}
                    </Grid>
                    <input
                        ref={uploadFieldRef}
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={handleFileChange}
                    />
                </Grid>
            </Button>
        </Tooltip>
    );
};
