/* eslint-disable no-param-reassign */
import { type Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface FolderState {
    foldersFilter?: Folder[];
}

const initialState = { foldersFilter: undefined } as FolderState;

export const folderFilterSlice = createSlice({
    name: 'foldersFilters',
    initialState,
    reducers: {
        setFolderFilter(state: FolderState, action: PayloadAction<FolderState>) {
            state.foldersFilter = action.payload.foldersFilter;
        },
    },
});
