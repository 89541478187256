import type { AttributeAssociationData } from '@local/api-clients/dist/colormap/enhancedColormapClient';
import {
    useLazyGetAssociationCollectionQuery,
    useLazyGetColormapCollectionQuery,
    usePostAssociationMutation,
    usePostColormapMutation,
} from '@local/api-clients/dist/colormap/enhancedColormapClient';
import { trackError } from '@local/metrics/';
import type { ColormapSaveData } from '@local/webviz/dist/components/Properties/Colormaps/ArtifactColorMapsControl/ColormapCreatorControl/ColormapCreatorControl.types';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ATTRIBUTES_PATH } from 'src/visualization/constants';

import { getObjectIdFromViewId } from '../snapshots/generateSnapshot';

export function useColormapCreator() {
    const [postColormap] = usePostColormapMutation();
    const [postAssociation] = usePostAssociationMutation();
    const [fetchColormaps] = useLazyGetColormapCollectionQuery();
    const [fetchAssociationCollection] = useLazyGetAssociationCollectionQuery();

    const [isPosting, setIsPosting] = useState(false);

    const { evouiEnableColormapsApi } = useFlags();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const createColorMap = async ({ colormapData, viewId, attributeName }: ColormapSaveData) => {
        if (!evouiEnableColormapsApi) {
            return '';
        }
        try {
            setIsPosting(true);
            const response = await postColormap({
                orgId,
                workspaceId,
                colormapData,
                'api-preview': 'opt-in',
            });

            if (response && 'data' in response) {
                const { data } = response;
                if (data && 'id' in data) {
                    const { id } = data;
                    const objectId = getObjectIdFromViewId(viewId);
                    await handleColormapAssociation(id, objectId, attributeName);
                    return id;
                }
            }
        } catch (error) {
            trackError(
                `Error creating colormap or association for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
            return '';
        } finally {
            setIsPosting(false);
        }
        return '';
    };

    const handleColormapAssociation = async (
        colormapId: string,
        objectId: string,
        attributeName: string,
    ) => {
        await createColorMapAssociation(objectId, {
            colormap_id: colormapId,
            name: attributeName,
            path: ATTRIBUTES_PATH,
        });
        await Promise.all([fetchColormapsData(), fetchAssociationCollectionData(objectId)]);
    };

    const fetchColormapsData = async () => {
        try {
            await fetchColormaps({
                orgId,
                workspaceId,
                'api-preview': 'opt-in',
            });
        } catch (error) {
            trackError(
                `Error loading colormaps for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
        }
    };

    const fetchAssociationCollectionData = async (objectId: string) => {
        try {
            await fetchAssociationCollection({
                orgId,
                workspaceId,
                objectId,
                'api-preview': 'opt-in',
            });
        } catch (error) {
            trackError(
                `Error loading association collection for objectId: ${objectId}`,
                JSON.stringify(error),
            );
        }
    };

    const createColorMapAssociation = async (
        objectId: string,
        attributeAssociationData: AttributeAssociationData,
    ) => {
        if (!evouiEnableColormapsApi) {
            return;
        }
        try {
            await postAssociation({
                orgId,
                workspaceId,
                attributeAssociationData,
                'api-preview': 'opt-in',
                objectId,
            });
        } catch (error) {
            trackError(
                `Error creating colormap association for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
        }
    };

    return { isPosting, createColorMap };
}
