import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { RETRIES, WAIT_TIME_MS } from 'src/constants';
import {
    FILE_API_ERROR_DELETED,
    FILE_API_ERROR_INITIAL_UPLOAD_FAILED,
    FILE_API_ERROR_WORKSPACE_NOT_FOUND,
} from 'src/strings';

import { enhancedFileClient } from './enhancedFileMiddleware';
import { fileClient } from './fileClient';
import type {
    GetFileByIdApiResponse,
    GetFileByPathApiResponse,
    UpdateFileByIdApiArg,
    UpsertFileByPathApiArg,
} from './GENERATED_fileClientEndpoints';
import { waitForMs } from './utils';

interface CustomUpdateFilesByIdApiArgs extends UpdateFileByIdApiArg {
    updatedVersion: File;
}

interface CustomUpsertFileByPathApiArgs extends UpsertFileByPathApiArg {
    uploadFile: File;
}

interface SeequentApiError {
    status: number;
    data: {
        status: number;
        title?: string;
        type?: string;
        detail?: string;
        upstream_type?: string;
    };
}

const isSeequentApiError = (
    err: FetchBaseQueryError | SerializedError,
): err is SeequentApiError => {
    if ('status' in err) {
        const data = err.data as object;
        return data !== undefined && 'status' in data && 'title' in data && 'type' in data;
    }
    return false;
};

const errorFromFetchError = (err: FetchBaseQueryError | SerializedError): Error | undefined => {
    if (isSeequentApiError(err)) {
        if (err.status === 410) {
            return new Error(FILE_API_ERROR_DELETED);
        }
        if (err.status === 404 && err.data.upstream_type?.endsWith('/workspace/not-found')) {
            return new Error(FILE_API_ERROR_WORKSPACE_NOT_FOUND);
        }
    }
    return undefined;
};

const uploadWithPolling = async (
    args: CustomUpsertFileByPathApiArgs | CustomUpdateFilesByIdApiArgs,
    queryApi: BaseQueryApi,
) => {
    const { workspaceId, organisationId } = args;

    // Get pre-signed Azure upload URL
    let response;
    if ('fileId' in args) {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.updateFileById.initiate({
                fileId: args.fileId,
                organisationId,
                workspaceId,
            }),
        );
    } else {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.upsertFileByPath.initiate({
                filePath: encodeURIComponent(args.filePath),
                organisationId,
                workspaceId,
            }),
        );
    }

    if (response.error) {
        const message =
            'status' in response.error && response.error.status === 410
                ? FILE_API_ERROR_DELETED
                : FILE_API_ERROR_INITIAL_UPLOAD_FAILED;
        const error = new Error(message) as unknown as FetchBaseQueryError;
        return { error };
    }

    // Track the new version ID, so we can wait until it's ready
    const {
        data: { file_id: fileId, upload, version_id: versionId },
    } = response;

    // Upload the file to blob storage
    const fetchBody = 'fileId' in args ? args.updatedVersion : args.uploadFile;
    await fetch(upload, {
        method: 'PUT',
        headers: {
            'x-ms-blob-type': 'BlockBlob',
        },
        body: fetchBody,
    });

    // We need to wait for the file to be uploaded to Azure before returning the response
    /* eslint-disable no-await-in-loop */
    let fetchCount = 0;

    while (fetchCount < RETRIES) {
        fetchCount += 1;

        await waitForMs(WAIT_TIME_MS);

        const {
            data: newFile,
            isError,
            error: fetchError,
        } = await queryApi.dispatch(
            enhancedFileClient.endpoints.getFileById.initiate(
                {
                    organisationId,
                    workspaceId,
                    fileId,
                    versionId,
                    includeVersions: true,
                },
                { forceRefetch: true },
            ),
        );

        if (isError) {
            const newError = errorFromFetchError(fetchError);
            if (newError) {
                return { error: newError as unknown as FetchBaseQueryError };
            }
        }

        // When the new file version is available we can invalidate the "get file by ID" cache for
        // this file, triggering a re-fetch (which shows the new version), then return the new file
        if (newFile) {
            return { data: newFile };
        }
    }

    const error = new Error(
        `File upload failed after ${(RETRIES * WAIT_TIME_MS) / 1000} seconds`,
    ) as unknown as FetchBaseQueryError;
    return { error };
};

export const injectedFileClient = fileClient.injectEndpoints({
    endpoints: (build) => ({
        customUploadFileById: build.mutation<GetFileByIdApiResponse, CustomUpdateFilesByIdApiArgs>({
            /**
             * Upsert a file by id. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
        customUpsertFileByPath: build.mutation<
            GetFileByPathApiResponse,
            CustomUpsertFileByPathApiArgs
        >({
            /**
             * Upsert a file by path. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
    }),
});

export const { useCustomUploadFileByIdMutation, useCustomUpsertFileByPathMutation } =
    injectedFileClient;
