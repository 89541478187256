import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    recycledToastButtonStart: {
        padding: '0 !important',
        minWidth: '0',
        color: 'inherit',
        textDecoration: 'underline',
        textTransform: 'none',
    },
    recycledToastButtonEnd: {
        minWidth: '0',
        color: 'inherit',
        textDecoration: 'underline',
        textTransform: 'none',
    },
    dateTimeCell: {
        paddingRight: '8px',
    },
}));
