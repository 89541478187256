import Search from '@local/web-design-system-2/dist/icons/Search';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';

import { FILTER_NO_RESULTS } from 'src/strings';

import { useStyles } from './SearchableCheckBoxGroup.styles';

export interface SearchableCheckBoxGroupProps<T> {
    placeholder: string;
    options: T[];
    handleChange: (selected: T[]) => void;
    selectedOptions: T[];
    getOptionLabel: (option: T) => string;
    getOptionKey: (option: T) => string;
    value: string;
    setValue: (value: string) => void;
}

export const SearchableCheckBoxGroup = <T extends object>(
    props: SearchableCheckBoxGroupProps<T>,
) => {
    const { classes } = useStyles();

    const {
        placeholder,
        options,
        handleChange,
        selectedOptions,
        getOptionLabel,
        getOptionKey,
        value,
        setValue,
    } = props;

    const filterOptions = createFilterOptions({
        stringify: getOptionLabel,
    });

    const filteredOptions = filterOptions(options, { inputValue: value, getOptionLabel });

    const isChecked = (option: T) =>
        !!selectedOptions.find((opt) => getOptionKey(opt) === getOptionKey(option));

    const handleCheckbox = (option: T) => {
        if (isChecked(option)) {
            handleChange(
                selectedOptions.filter((opt) => getOptionKey(opt) !== getOptionKey(option)),
            );
        } else {
            handleChange(selectedOptions.concat(option));
        }
    };

    const LabelledCheckbox = ({ label, control }: { label: string; control: JSX.Element }) => {
        const typographyRef = useRef<HTMLDivElement>(null);
        const [isTextOverFlowing, setIsTextOverflowing] = useState(false);

        useEffect(() => {
            const typography = typographyRef.current;
            setIsTextOverflowing(
                typography ? typography.offsetWidth < typography.scrollWidth : false,
            );
        }, [typographyRef.current]);

        return (
            <Tooltip title={isTextOverFlowing ? label : ''}>
                <FormControlLabel
                    className={classes.formControl}
                    label={
                        <Typography ref={typographyRef} color="InfoText" fontSize="small" noWrap>
                            {label}
                        </Typography>
                    }
                    control={control}
                />
            </Tooltip>
        );
    };

    return (
        <>
            <TextField
                InputProps={{
                    endAdornment: <Search />,
                }}
                variant="outlined"
                className={classes.searchBox}
                placeholder={placeholder}
                onChange={(event) => setValue(event.target.value)}
                value={value}
                automation-id="searchable-checkbox-group-search-bar"
            />
            <Box component="div" className={classes.container}>
                <FormGroup className={classes.formGroup}>
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option) => (
                            <LabelledCheckbox
                                key={getOptionKey(option)}
                                label={getOptionLabel(option)}
                                control={
                                    <Checkbox
                                        checked={isChecked(option)}
                                        onChange={() => handleCheckbox(option)}
                                        automation-id="go-dropdown-filter-checkbox"
                                    />
                                }
                            />
                        ))
                    ) : (
                        <Typography automation-id="no-results">{FILTER_NO_RESULTS}</Typography>
                    )}
                </FormGroup>
            </Box>
        </>
    );
};
