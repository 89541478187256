import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export function LoadingRow() {
    return (
        <TableRow>
            <TableCell sx={{ paddingLeft: '30px' }}>
                <Skeleton variant="text" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" />
            </TableCell>
            <TableCell>
                <Skeleton variant="text" />
            </TableCell>
            <TableCell>
                <Skeleton variant="circular" height={32} width={32} />
            </TableCell>
        </TableRow>
    );
}
