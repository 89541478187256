import type { GetObjectResponse } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { extractObjectSchemaName } from '@local/api-clients/dist/utils/extractObjectSchemaName';
import { KeyValueInfoTable } from '@local/web-design-system-2/dist/components/KeyValueInfoTable/KeyValueInfoTable';
import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ObjectViewOptionsMenu } from 'src/components/sidebar/ObjectViewOptionsMenu';
import { OpenInViewerButton } from 'src/components/sidebar/OpenInViewerButton';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import { FilterProperties } from 'src/pages/workspacePage/workspaceContent/sidebar/FilterProperties';
import {
    CREATED_BY,
    CREATED_ON,
    DATA_TYPE,
    OBJECT_ID,
    OBJECT_PATH,
    PROPERTIES,
    SOURCE,
    VERSION_ID,
} from 'src/strings';
import { hasMultipleOpenOptions } from 'src/utils/viewObjectUtils';

import { Icon } from './Icon';

interface Props {
    isFile: boolean;
    data?: GetObjectResponse;
    selectedFilter?: Folder;
}

export const Properties = ({ isFile, data, selectedFilter }: Props) => {
    const featureFlags = useFlags();

    if (!isFile) {
        // Show filter properties
        return <FilterProperties data={selectedFilter} />;
    }

    if (!data) {
        return null;
    }

    const showOpenButtonIcon = !hasMultipleOpenOptions(data, featureFlags);
    // Only have the viewer button be primary if other options are enabled.
    const viewerButtonVariant =
        featureFlags.evouiOpenInDriver || featureFlags.evouiOpenInBlocksync
            ? 'primary'
            : 'secondary';

    return (
        <Stack spacing={2}>
            <Typography variant="h6">{PROPERTIES}</Typography>
            <Divider />
            <Stack alignItems="center" direction="row" spacing={1}>
                <Icon schema={data.object.schema} />
                <Typography automation-id="object-name" variant="body1" title={data.object.name}>
                    {data.object.name}
                </Typography>
            </Stack>
            <Stack>
                <KeyValueInfoTable
                    items={[
                        {
                            label: DATA_TYPE,
                            safeToLogLabel: true,
                            value: (
                                <span style={{ textTransform: 'capitalize' }}>
                                    {extractObjectSchemaName(data.object.schema)}
                                </span>
                            ),
                        },
                        {
                            label: OBJECT_PATH,
                            safeToLogLabel: true,
                            value: data.object_path,
                        },
                        data.object.source && {
                            label: SOURCE,
                            safeToLogLabel: true,
                            value: data.object.source,
                        },
                        {
                            label: VERSION_ID,
                            safeToLogLabel: true,
                            value: data.version_id,
                        },
                        {
                            label: OBJECT_ID,
                            safeToLogLabel: true,
                            value: data?.object_id,
                        },
                        {
                            label: CREATED_ON,
                            safeToLogLabel: true,
                            value: <PropertyDate timestamp={data.created_at} />,
                        },
                        {
                            label: CREATED_BY,
                            safeToLogLabel: true,
                            value: data.created_by?.name,
                        },
                        ...Object.keys(data.object.tags).map((key) => ({
                            label: key,
                            safeToLogLabel: true,
                            value: data.object.tags[key],
                        })),
                    ].filter(Boolean)}
                />
            </Stack>
            <div>
                <OpenInViewerButton
                    objectSchema={data.object.schema}
                    variant={viewerButtonVariant}
                    showIcon={showOpenButtonIcon}
                />
                <ObjectViewOptionsMenu
                    objectDetail={data}
                    variant="secondary"
                    showIcon={showOpenButtonIcon}
                />
            </div>
        </Stack>
    );
};
