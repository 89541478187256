import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    buttonText: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(2),
        gap: theme.spacing(2),
    },
    secondaryButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '15px',
        minWidth: '250px',
        alignContent: 'center',
        gap: theme.spacing(2),
    },
}));
