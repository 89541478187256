import { User } from '@local/login';
import {
    DateTimeCell,
    StringCell,
} from '@local/web-design-system-2/dist/components/GenericListing/ListCells';
import { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { defaultStringSort, timestampSort, userSort } from '@local/workspaces/dist/utils/Sorting';
import { Theme } from '@mui/material';

import {
    OBJECT_HEADER_NAME,
    OBJECT_HEADER_MODIFIED_BY,
    OBJECT_HEADER_LAST_MODIFIED,
    OBJECT_HEADER_DATA_TYPE,
    OBJECT_HEADER_WORKSPACE,
} from 'src/strings';

import { EmptyCell, FileCell, FolderCell } from './fieldCells';
import { SchemaCell } from './SchemaCell';
import { UserCell } from './UserCell';

export function folderRowDefinition(theme: Theme): FieldDefinition[] {
    return [
        {
            key: 'name',
            label: 'Name',
            component: FolderCell,
            styles: {
                color: theme.palette.primary.dark,
            },
        } as FieldDefinition<string>,
        {
            key: 'name2',
            label: 'Name',
            component: EmptyCell,
        } as FieldDefinition<string>,
        {
            key: 'name3',
            label: 'Name',
            component: EmptyCell,
        } as FieldDefinition<string>,
        {
            key: 'name4',
            label: 'Name',
            component: EmptyCell,
        } as FieldDefinition<string>,
    ];
}

export function objectDefinition(theme: Theme): FieldDefinition[] {
    return [
        {
            key: 'name',
            label: OBJECT_HEADER_NAME,
            component: FileCell,
            sortFunction: defaultStringSort,
            styles: {
                color: theme.palette.primary.dark,
            },
        } as FieldDefinition<string>,
        {
            key: 'schema',
            label: OBJECT_HEADER_DATA_TYPE,
            component: SchemaCell,
            sortFunction: defaultStringSort,
            styles: {
                width: '15%',
                textTransform: 'capitalize',
                color: theme.palette.primary.dark,
            },
        } as FieldDefinition<string>,
        {
            key: 'modified_by',
            label: OBJECT_HEADER_MODIFIED_BY,
            component: UserCell,
            sortFunction: userSort,
            styles: {
                width: '15%',
                color: theme.palette.primary.dark,
            },
        } as FieldDefinition<User>,
        {
            key: 'modified_at',
            label: OBJECT_HEADER_LAST_MODIFIED,
            component: DateTimeCell,
            sortFunction: timestampSort,
            styles: {
                width: '15%',
                color: theme.palette.primary.dark,
            },
        } as FieldDefinition<string>,
    ];
}

const recycledObjectPalleteValue = 900;
export function recycledObjectDefinition(theme: Theme): FieldDefinition[] {
    return [
        {
            key: 'name',
            label: OBJECT_HEADER_NAME,
            component: FileCell,
            sortFunction: defaultStringSort,
            sx: {
                color: theme.palette.grey[recycledObjectPalleteValue],
            },
        } as FieldDefinition<string>,
        {
            key: 'schema',
            label: OBJECT_HEADER_DATA_TYPE,
            component: SchemaCell,
            sortFunction: defaultStringSort,
            sx: {
                width: '15%',
                textTransform: 'capitalize',
                color: theme.palette.grey[recycledObjectPalleteValue],
            },
        } as FieldDefinition<string>,
        {
            key: 'workspace_name',
            label: OBJECT_HEADER_WORKSPACE,
            component: StringCell,
            // Off until we have a sort function for workspace name
            // sortFunction: defaultStringSort,
            sx: {
                width: '15%',
                color: theme.palette.grey[recycledObjectPalleteValue],
            },
        } as FieldDefinition<string>,
        {
            key: 'created_at',
            label: OBJECT_HEADER_LAST_MODIFIED,
            component: DateTimeCell,
            sortFunction: timestampSort,
            sx: {
                width: '15%',
                color: theme.palette.grey[recycledObjectPalleteValue],
            },
        } as FieldDefinition<string>,
    ];
}
