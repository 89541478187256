import { getCurrentEvoInstance } from '@local/login';
import { useLoadBentleyLicenseAccessQuery } from '@local/login/dist/apiClients/entitlementsClient/requests/BentleyEntitlementsAPI';
import { trackError } from '@local/metrics';
import { CircularProgress, Grid } from '@mui/material';
import { Navigate } from 'react-router-dom';

const SPINNER_SIZE = 40;
const calcCenter = (size: number) => `calc(50% - ${size / 2}px)`;

/**
 * Route guard that ensures that the user has a valid licenses response,
 * this requires that there is already a valid list of ORGS, this should be wrapped
 * by the `OrgRouteGuard`
 */
export function LicenseGuard({
    children,
    licensedServices,
}: {
    children: JSX.Element;
    licensedServices: string[];
}) {
    const service = 'evo';
    const { org, hub } = getCurrentEvoInstance();

    // Checking for licensed services access
    const { isLoading, isError } = useLoadBentleyLicenseAccessQuery({
        service,
        hubCode: hub?.code || '',
        orgId: org?.id || '',
        requiredScope: licensedServices,
    });

    if (isLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress
                    automation-id="license-hub-guard"
                    size={SPINNER_SIZE}
                    color="primary"
                    sx={{
                        position: 'absolute',
                        top: calcCenter(SPINNER_SIZE),
                    }}
                />
            </Grid>
        );
    }

    if (isError) {
        trackError('Invalid Bentley license scope provided');
        return <Navigate to="/login?error=no_access_license" />;
    }

    return children;
}
