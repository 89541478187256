import { DRAWER_WIDTH_UNITS } from '@local/web-design-system/dist/styles/constants';
import { fonts } from '@local/web-design-system/dist/styles/fonts';
import { getSpacing } from '@local/web-design-system/dist/styles/getSpacing';
import { makeStyles } from 'tss-react/mui';

const calcWidth = (calcPx: number) => `calc(100% - ${calcPx}px)`;
export const FOOTER_HEIGHT_UNITS = 2.5;
export const useStyles = makeStyles()((theme) => {
    const marginLeft = theme.spacing(DRAWER_WIDTH_UNITS);

    return {
        appLogo: {
            width: theme.spacing(5.25),
            height: theme.spacing(5.25),
        },
        appLogoLong: {
            height: theme.spacing(8),
            width: theme.spacing(24),
        },
        appBarShift: {
            [theme.breakpoints.up('sm')]: {
                marginLeft,
                width: calcWidth(getSpacing(theme, DRAWER_WIDTH_UNITS) + 1),
            },
        },
        footer: {
            background: theme.palette.grey[100],
            color: theme.palette.grey[400],
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
            fontSize: '12px',
            fontWeight: fonts.weights.regular,
            height: theme.spacing(FOOTER_HEIGHT_UNITS),
            marginBottom: theme.spacing(0.5),
            paddingRight: theme.spacing(2),
            position: 'absolute',
            bottom: 0,
            width: `calc(100vw - ${theme.spacing(2)})`,
        },
        contentInner: {
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
            height: '100%',
        },
        webVizContent: {
            display: 'grid',
            height: '100%',
            padding: 0,
        },
    };
});
