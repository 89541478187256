import { getAdminFromBentleyAccessToken } from '@local/login';
import { Messages as WDS2Messages } from '@local/messages-wds2/dist/Messages';
import { Messages } from '@local/messages/dist/Messages';
import { ManageUser } from '@local/user-manage/dist/apiClients/entities';
import { useGetUsersQuery } from '@local/user-manage/dist/apiClients/manageClient/enhancedUserManageMiddlewareBentley';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { Restricted } from '@local/workspaces/dist/components/Restricted/Restricted';
import { BackButton } from '@local/workspaces/dist/components/titleRow/buttons/BackButton';
import { USERS_PAGE } from '@local/workspaces/dist/urls';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

import {
    BACK_TO_USERS,
    NOT_FOUND_MESSAGE,
    RESTRICTED_NO_PERMISSION_USERS,
    USER_NOT_FOUND_TITLE,
    EDIT_USER_TITLE,
    ADMINISTRATORS_TITLE,
} from 'src/strings';

import { PageContent } from '../../components/pageContent/PageContent';
import { UserContentContainer } from '../../components/userContentContainer/UserContentContainer';
import { USER_MANAGEMENT_PAGE } from '../../urls';
import { GenericNotFoundPage } from '../notFoundPage/GenericNotFoundPage';
import { useStyles } from './EditUser.styles';
import { UsersWorkspaces } from './workspacePermissions/UsersWorkspaces';

export function BentleyEditUserPage() {
    setDocumentTitle(EDIT_USER_TITLE);
    const { classes } = useStyles();
    const params = useParams();
    const isAdmin = getAdminFromBentleyAccessToken();
    const { data: evoUsersList } = useGetUsersQuery({
        org_id: getOrgUuidFromParams(params),
    });

    const userDetails = evoUsersList?.users.find((user: ManageUser) => user.id === params.userUuid);

    const { evouiMoreUserManagement } = useFlags();

    if (!isAdmin) {
        return <Restricted message={RESTRICTED_NO_PERMISSION_USERS} />;
    }

    if (userDetails === undefined) {
        return <GenericNotFoundPage title={USER_NOT_FOUND_TITLE} message={NOT_FOUND_MESSAGE} />;
    }

    return (
        <PageContent
            pageTitle={`${userDetails.given_name} ${userDetails.family_name}`}
            breadcrumbSegments={[
                { name: ADMINISTRATORS_TITLE, path: `/${params.orgUuid}/${USERS_PAGE}` },
                { name: `${userDetails.given_name} ${userDetails.family_name}` },
            ]}
            titleActions={
                <BackButton
                    text={BACK_TO_USERS}
                    destination={`/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`}
                />
            }
        >
            <UserContentContainer
                columnContent={
                    <>
                        <Grid item xs={12}>
                            <Typography color="primary" variant="subtitle2">
                                {`${userDetails.given_name} ${userDetails.family_name}`}
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle2">
                                {userDetails.email}
                            </Typography>
                        </Grid>
                        <Messages queue="edit-user" className={classes.messageQueue} />
                        <WDS2Messages queue="edit-user" className={classes.messageQueue} />
                    </>
                }
                panelContent={evouiMoreUserManagement && <UsersWorkspaces />}
            />
        </PageContent>
    );
}
