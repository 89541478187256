import { Camera, Cartesian3, Scene, SceneMode } from '@cesium/engine';
import { MapControlTooltip } from '@local/map-viewer/dist/controls/MapControlsTooltip';
import { GlobeIcon } from '@local/web-design-system';
import ZoomInIcon from '@local/web-design-system/dist/icons/Map/ZoomInIcon';
import ZoomOutIcon from '@local/web-design-system/dist/icons/Map/ZoomOutIcon';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import { useState } from 'react';

import { useStyles } from './MapControls.styles';

interface MapControlsProps {
    scene?: Scene | undefined; // eslint-disable-line react/no-unused-prop-types
    camera?: Camera | undefined; // eslint-disable-line react/no-unused-prop-types
}

const ZOOM_FACTOR = 2;
const ZOOM_DURATION = 1;
const INITIAL_ZOOM_LEVEL = 20000000;

const ZoomIn = ({ camera }: MapControlsProps) => {
    const { classes } = useStyles();

    const zoomIn = () => {
        if (!camera) {
            return;
        }
        camera.flyTo({
            destination: Cartesian3.fromRadians(
                camera.positionCartographic.longitude,
                camera.positionCartographic.latitude,
                camera.positionCartographic.height * (1 / ZOOM_FACTOR),
            ),
            duration: ZOOM_DURATION, // Duration in seconds
        });
    };
    return (
        <MapControlTooltip title="Zoom In" placement="right-end">
            <IconButton
                onClick={zoomIn}
                className={classes.zoomControl}
                automation-id="zoom-control-in"
            >
                <ZoomInIcon className={classes.zoomIcon} />
            </IconButton>
        </MapControlTooltip>
    );
};

const ZoomOut = ({ camera }: MapControlsProps) => {
    const { classes } = useStyles();

    const zoomOut = () => {
        if (!camera) {
            return;
        }
        camera.flyTo({
            destination: Cartesian3.fromRadians(
                camera.positionCartographic.longitude,
                camera.positionCartographic.latitude,
                camera.positionCartographic.height * ZOOM_FACTOR,
            ),
            duration: ZOOM_DURATION, // Duration in seconds
        });
    };
    return (
        <MapControlTooltip title="Zoom Out" placement="right-end">
            <IconButton
                onClick={zoomOut}
                className={classes.zoomControl}
                automation-id="zoom-control-out"
            >
                <ZoomOutIcon className={classes.zoomIcon} />
            </IconButton>
        </MapControlTooltip>
    );
};

const ResetZoom = ({ camera }: MapControlsProps) => {
    const { classes } = useStyles();

    const resetZoom = () => {
        if (!camera) {
            return;
        }

        camera.flyTo({
            destination: Cartesian3.fromRadians(
                camera.positionCartographic.longitude,
                camera.positionCartographic.latitude,
                INITIAL_ZOOM_LEVEL, // Adjust this value to set the initial zoom level
            ),
            duration: ZOOM_DURATION, // Duration in seconds
        });
    };
    return (
        <MapControlTooltip title="Reset Zoom" placement="right-end">
            <IconButton
                onClick={resetZoom}
                className={classes.zoomControl}
                automation-id="zoom-control-reset"
            >
                <GlobeIcon className={classes.zoomIcon} />
            </IconButton>
        </MapControlTooltip>
    );
};

const MapTransformation = ({ scene }: MapControlsProps) => {
    const { classes } = useStyles();

    const [isThreeDimensional, setIsThreeDimensional] = useState(true);

    if (!scene) {
        return null;
    }

    const toggleTransformation = () => {
        if (scene.mode === SceneMode.SCENE2D) {
            scene.morphTo3D(1);
            setIsThreeDimensional(true);
        } else {
            scene.morphTo2D(1);
            setIsThreeDimensional(false);
        }
    };

    return (
        <MapControlTooltip
            title={`Toggle to ${isThreeDimensional ? '2D' : '3D'} mode`}
            placement="right-end"
        >
            <div
                className={classes.zoomControl}
                onClick={toggleTransformation}
                automation-id="map-transformation-control"
            >
                <Typography variant="button" className={classes.zoomIcon}>
                    {isThreeDimensional ? '2D' : '3D'}
                </Typography>
            </div>
        </MapControlTooltip>
    );
};

export const MapControls = ({ scene, camera }: MapControlsProps) => {
    const { classes } = useStyles();

    return (
        <Grid className={classes.zoomControls} automation-id="cesium-zoom-controls">
            <ZoomIn camera={camera} scene={scene} />
            <ZoomOut camera={camera} scene={scene} />
            <ResetZoom camera={camera} scene={scene} />
            <MapTransformation camera={camera} scene={scene} />
        </Grid>
    );
};
