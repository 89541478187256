import { Upload } from '@local/web-design-system-2';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Typography from '@mui/material/Typography/Typography';

import { DRAG_AND_DROP } from 'src/strings';

interface Props {
    colSpan: number;
}

export const TableDragAndDrop = ({ colSpan }: Props) => (
    <tbody>
        <TableRow sx={{ height: '90px' }} automation-id="table-drag-and-drop">
            <TableCell colSpan={colSpan}>
                <Stack spacing={1} direction="column" alignItems="center" justifyContent="center">
                    <Upload height="40px" width="40px" />
                    <Typography variant="subtitle2">{DRAG_AND_DROP}</Typography>
                </Stack>
            </TableCell>
        </TableRow>
    </tbody>
);
