import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics';
import Copy from '@local/web-design-system-2/dist/icons/Copy';
import Delete from '@local/web-design-system-2/dist/icons/Delete';
import Download from '@local/web-design-system-2/dist/icons/Download';
import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useDeleteFileByIdMutation,
    useLazyGetFileByIdQuery,
} from 'src/apiClients/enhancedFileMiddleware';
import type { FileVersionResponse, ListFile } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useSearchParamsContext } from 'src/contexts/SearchParamsContext';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import {
    COPY,
    DELETE,
    DELETE_FILE_CANCEL,
    DELETE_FILE_CONFIRM,
    DELETE_FILE_DIALOG_CONTENT,
    DELETE_FILE_DIALOG_TITLE,
    DELETE_FILE_FAILED,
    DOWNLOAD,
    COPIED_TO_CLIPBOARD,
    DOWNLOAD_FILE_FAILED,
    INSUFFICIENT_WORKSPACE_PERMISSION,
} from 'src/strings';

import { useStyles } from './FileActions.styles';

interface ActionProps {
    iconOnly?: boolean;
    onClick?: () => void;
}

function DownloadAction({ iconOnly, onClick }: ActionProps) {
    const { classes } = useStyles();

    if (iconOnly && onClick) {
        return (
            <Tooltip title={DOWNLOAD}>
                <IconButton
                    className={classes.iconButton}
                    automation-id="download-button"
                    onClick={onClick}
                >
                    <Download />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <Download />
            <Typography className={classes.actionText}>{DOWNLOAD}</Typography>
        </Grid>
    );
}

function DeleteAction({ iconOnly, onClick }: ActionProps) {
    const { classes } = useStyles();
    const { workspaceUserRole } = useWorkspaceContext();

    const disabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    if (iconOnly && onClick) {
        return (
            <Tooltip title={disabled ? INSUFFICIENT_WORKSPACE_PERMISSION : DELETE}>
                <span>
                    <IconButton
                        className={classes.iconButton}
                        automation-id="delete-button"
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <Delete />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <Delete />
            <Typography className={classes.actionText}>{DELETE}</Typography>
        </Grid>
    );
}

function CopyAction() {
    const { classes } = useStyles();

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <Copy />
            <Typography className={classes.actionText}>{COPY}</Typography>
        </Grid>
    );
}

interface ConfirmDeleteDialogProps {
    open: boolean;
    onClose: (confirm: boolean) => void;
}

// TODO Style this correctly, potentially make generic and move to WDS2?
function ConfirmDeleteDialog({ open, onClose }: ConfirmDeleteDialogProps) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle automation-id="dialog-title">{DELETE_FILE_DIALOG_TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText automation-id="dialog-description">
                    {DELETE_FILE_DIALOG_CONTENT}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    color="secondary"
                    variant="contained"
                    onClick={() => onClose(false)}
                    automation-id="dialog-cancel-button"
                >
                    {DELETE_FILE_CANCEL}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    onClick={() => onClose(true)}
                    automation-id="dialog-confirm-button"
                >
                    {DELETE_FILE_CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface Props {
    file: Pick<ListFile, 'file_id' | 'name' | 'version_id'>;
    /** If provided, will be used for context when issuing download or delete queries */
    version?: FileVersionResponse;
    /** Whether to display icons, otherwise will display a dropdown menu of options */
    icons?: boolean;
}

export function FileActions({ file, version, icons }: Props) {
    const { classes } = useStyles();
    const { addMessage } = useMessagesContext();
    const params = useParams();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParamsContext();
    const { workspaceUserRole } = useWorkspaceContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fetchFile, { isLoading: isDownloading }] = useLazyGetFileByIdQuery();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileByIdMutation();

    const isDisabled = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    const handleDownload = async () => {
        handleClose();
        const { data, isError, error } = await fetchFile({
            organisationId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
            fileId: file.file_id,
            versionId: version?.version_id,
        });

        if (isError) {
            trackError('Error downloading file', JSON.stringify(error));
            addMessage({
                message: DOWNLOAD_FILE_FAILED,
                severity: 'error',
            });
            return;
        }

        if (!data) {
            return;
        }

        // When the download button is clicked, we fetch the file by ID from the API, which gives us the
        // download link. This code is to automatically trigger that download link, instead of presenting the
        // user with another download button to click again.
        const downloadLink = document.createElement('a');
        downloadLink.href = data.download;
        downloadLink.download = file.name;
        downloadLink.click();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(version!.version_id);
        addMessage({
            message: COPIED_TO_CLIPBOARD,
            severity: 'success',
        });
        handleClose();
    };

    const openDeleteDialog = () => {
        handleClose();
        setDeleteDialogOpen(true);
    };

    const handleDelete = async (isConfirmed: boolean | null) => {
        setDeleteDialogOpen(false);

        if (!isConfirmed || isDisabled) {
            return;
        }
        try {
            await deleteFile({
                organisationId: getOrgUuidFromParams(params),
                workspaceId: getSelectedWorkspaceFromParams(params),
                fileId: file.file_id,
            }).unwrap();

            // todo usePersistedState should be able to do this but it isn't working, investigate this
            /** @see https://seequent.atlassian.net/browse/CENPLAT-19894 */
            searchParams.delete('id');
            setSearchParams(searchParams);
        } catch (error) {
            trackError('Error deleting file', JSON.stringify(error));
            addMessage({
                message: DELETE_FILE_FAILED,
                severity: 'error',
            });
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (isDeleting || isDownloading) {
        if (icons) {
            return (
                <div className={classes.icons}>
                    <Skeleton variant="circular" width={38} height={38} />
                </div>
            );
        }
        return <Skeleton variant="circular" width={38} height={38} />;
    }

    if (icons) {
        // Render an inline list of icon buttons only
        return (
            <>
                <div className={classes.icons}>
                    <DownloadAction onClick={handleDownload} iconOnly />
                    {!version && (
                        <DeleteAction onClick={() => setDeleteDialogOpen(true)} iconOnly />
                    )}
                    {version && <CopyAction />}
                </div>
                <ConfirmDeleteDialog open={deleteDialogOpen} onClose={handleDelete} />
            </>
        );
    }

    // Render a menu with dropdown options
    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton size="large" onClick={handleClick} automation-id="overflow-icon">
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
                <MenuItem onClick={handleDownload} automation-id="menu-menu-item-download-file">
                    <DownloadAction />
                </MenuItem>
                {!version && (
                    <MenuItem
                        disabled={isDisabled}
                        onClick={openDeleteDialog}
                        automation-id="menu-menu-item-delete-file"
                    >
                        <DeleteAction />
                    </MenuItem>
                )}
                {version && (
                    <MenuItem
                        onClick={handleCopy}
                        disabled={isDisabled}
                        automation-id="menu-menu-item-copy-file-id"
                    >
                        <CopyAction />
                    </MenuItem>
                )}
            </Menu>
            <ConfirmDeleteDialog open={deleteDialogOpen} onClose={handleDelete} />
        </>
    );
}
