import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    emptyContainer: {
        color: theme.palette.primary.dark,
        gap: theme.spacing(2),
        justifyContent: 'center',
    },
    emptyText: {
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: '100%',
    },
}));
