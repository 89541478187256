import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    zoomControls: {
        position: 'absolute',
        top: '16px',
        right: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    zoomControl: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        backgroundColor: '#282A2E',
        color: '#E2E0E0',
        borderRadius: '4px !important',
        '&:hover': {
            backgroundColor: '#0F1012',
            boxShadow: '0px 0px 6px 0px #00000026',
            color: '#FFFFFF',
            cursor: 'pointer',
        },
    },
    zoomIcon: {
        width: '16px',
        height: '16px',
        color: '#FFFFFF',
    },
}));
