import { FilePropertiesEmpty } from '@local/svgs/dist/svg/FilePropertiesEmpty';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import { PanelDragAndDrop } from 'src/components/dragAndDrop/PanelDragAndDrop';
import { Header } from 'src/components/sidebar/Header';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileDragAndDrop } from 'src/hooks/useFileDragAndDrop';
import { FILE_PROPERTIES, NO_SELECTED_FILE, UPLOAD_FILES } from 'src/strings';

import { useStyles } from './Empty.styles';

interface Props {
    workspaceId: string;
    error?: string;
}

export const Empty = ({ workspaceId, error }: Props) => {
    const { classes } = useStyles();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const { isHighlighted, ...dragProps } = useFileDragAndDrop(workspaceId, orgId);
    const { workspaceUserRole } = useWorkspaceContext();

    const isEditor = workspaceUserRole ? hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    return (
        <>
            <Header text={FILE_PROPERTIES} className={classes.emptyHeader} />
            <Grid container className={classes.container} automation-id="empty-sidebar">
                {error && <Alert severity="error">{error}</Alert>}
                <div>
                    <FilePropertiesEmpty />
                </div>
                <Typography variant="body2" className={classes.text}>
                    {NO_SELECTED_FILE}
                </Typography>
            </Grid>
            {isEditor && (
                <>
                    <Header text={UPLOAD_FILES} />
                    <Grid
                        {...dragProps}
                        className={isHighlighted ? classes.highlight : classes.dragAndDropContainer}
                    >
                        <div>
                            <PanelDragAndDrop isHighlighted={isHighlighted} />
                        </div>
                    </Grid>
                </>
            )}
        </>
    );
};
