import { CesiumWidget } from '@cesium/engine';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useEffect, useRef, useState } from 'react';

import { MapControls } from './cesium/Map-Controls/MapControls';

export const CesiumSearchPage = () => {
    const cesiumWidgetRef = useRef<CesiumWidget | null>(null);

    const [isCesiumWidgetReady, setIsCesiumWidgetReady] = useState(false);

    setDocumentTitle('Search');

    useEffect(() => {
        if (cesiumWidgetRef.current) {
            return;
        }
        cesiumWidgetRef.current = new CesiumWidget('cesiumContainer');
        setIsCesiumWidgetReady(true);
    }, []);

    return (
        <div
            id="cesiumContainer"
            automation-id="cesium-map-container"
            style={{
                width: '100%',
                height: '80vh',
                position: 'relative',
            }}
        >
            {isCesiumWidgetReady && (
                <MapControls
                    scene={cesiumWidgetRef.current?.scene}
                    camera={cesiumWidgetRef.current?.camera}
                />
            )}
        </div>
    );
};
