import type { AttributeProps } from '@local/webviz/dist/components/Properties/AttributesControl';

import { getGooseObjectById } from 'src/store/goose/selectors';
import { useAppSelector } from 'src/store/store';
import { getTreeItemById } from 'src/store/visualization/selectors';
import { ROOT_TREE_ID } from 'src/strings';
import { extractSchema } from 'src/utils/extractSchema';
import { SUPPORTED_ATTRIBUTE_TYPES } from 'src/utils/supportedTypes';
import { Schemas } from 'src/visualization/constants';
import type { ObjectAttributeType } from 'src/visualization/DockableDialogs/DockableDialogs.types';
import type { DownholeCollectionType, TriangleMeshType } from 'src/visualization/types';
import {
    createAttributeId,
    getTriangleMeshAttributes,
} from 'src/visualization/utils/snapshotUtils';

export function useSelectedObjectsAttributes(selectedObjectIds: string[]) {
    let attributes: AttributeProps = {};
    const firstSelectedObject = useAppSelector(getGooseObjectById(selectedObjectIds[0]));
    const firstSelectedTreeItem = useAppSelector(getTreeItemById(selectedObjectIds[0]));
    const parentObject = useAppSelector(getGooseObjectById(firstSelectedTreeItem?.parentId ?? ''));

    if (firstSelectedTreeItem?.parentId === ROOT_TREE_ID) {
        const itemSchema = extractSchema(firstSelectedObject?.object.schema ?? '');
        if (itemSchema === Schemas.TriangleMeshSchema) {
            attributes = getTriangleMeshAttributes(
                firstSelectedObject?.object as TriangleMeshType,
            )?.reduce(reduceAttribute, {});
        } else {
            attributes = firstSelectedObject?.object.attributes?.reduce(reduceAttribute, {});
        }
    } else if (parentObject?.object) {
        const { collections } = parentObject.object as DownholeCollectionType;
        const selectedCollection = collections.find(
            (collection) => collection.name === firstSelectedTreeItem?.name,
        );
        attributes = selectedCollection?.from_to?.attributes?.reduce(reduceAttribute, {});
    }

    return { firstSelectedTreeItem, attributes };
}

function reduceAttribute(
    accumulator: AttributeProps,
    { name, values, attribute_type }: ObjectAttributeType,
) {
    if (SUPPORTED_ATTRIBUTE_TYPES.includes(attribute_type)) {
        const propertyId = createAttributeId(values.data);
        const currentAttribute = { [name]: { propertyId, key: `${propertyId}:${name}` } };
        return {
            ...accumulator,
            ...currentAttribute,
        };
    }
    // eslint-disable-next-line no-console
    console.warn(`Unsupported attribute type: ${attribute_type}`);
    return accumulator;
}
