import { useState } from 'react';

import { DEFAULT_PAGE_SIZE } from '../utils/pagination';
import type { PaginationParams } from '../utils/pagination';

/**
 * Centralised logic for managing pagination state
 */
export const usePagination = () => {
    const [paginationParams, setPaginationParams] = useState<PaginationParams>({
        page: 0, // Pagination is zero indexed
        pageSize: DEFAULT_PAGE_SIZE,
        searchTerm: '',
    });

    const handleNextPage = () => {
        setPaginationParams((currentParams) => ({
            ...currentParams,
            page: currentParams.page + 1,
        }));
    };

    const handlePreviousPage = () => {
        setPaginationParams((currentParams) => ({
            ...currentParams,
            page: currentParams.page - 1,
        }));
    };

    const handleSetPage = (newPage: number) => {
        setPaginationParams((currentParams) => ({
            ...currentParams,
            page: newPage,
        }));
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setPaginationParams((currentParams) => ({
            ...currentParams,
            pageSize: newPageSize,
            page: 0,
        }));
    };

    const handleSearch = (search: string) => {
        setPaginationParams((currentParams) => ({
            ...currentParams,
            searchTerm: search,
            page: 0,
        }));
    };

    return {
        ...paginationParams,
        setPaginationParams,
        handleNextPage,
        handlePreviousPage,
        handleSetPage,
        handlePageSizeChange,
        handleSearch,
    };
};
